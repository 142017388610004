@import './variables';

@mixin border-radius($val) {
  border-radius: $val;
  -moz-border-radius: $val;
  -webkit-border-radius: $val;
}

//---- Font Custom ----//
@mixin font-custom($fWeight, $fSize, $lHeight) {
  font-size: $fSize !important;
  line-height: $lHeight !important;
  font-weight: $fWeight !important;
}

@mixin font-weight-bold {
  font-weight: 700 !important;
}

@mixin font-weight-semibold {
  font-weight: 600;
}

@mixin font-weight-medium {
  font-weight: 500;
}

@mixin font-weight-regular {
  font-weight: 400;
}

@mixin flex-basic-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column-center {
  @include flex-basic-center;
  flex-direction: column;
}

@mixin commonScrollBar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px $backgroundColor !important;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarBgColor !important;
    border-radius: 10px;
  }
}

@mixin circularNode {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 0.5rem;
  color: $white;
}

@mixin circularNodeBorder($color) {
  border: 3px solid $color;
  background: $color;
}
