@use 'sass:math';
//width per clasess

@for $i from 0 through 100 {
  .w-#{$i} {
    width: round(percentage(math.div($i, 100)));
  }
}

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mr-#{$i} {
    margin-right: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .ml-#{$i} {
    margin-left: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mb-#{$i} {
    margin-bottom: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .p-#{$i} {
    padding: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pl-#{$i} {
    padding-left: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pr-#{$i} {
    padding-right: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pt-#{$i} {
    padding-top: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .pb-#{$i} {
    padding-bottom: #{math.div($i, 16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .border-radius-#{$i} {
    @include border-radius(#{$i}px);
  }
}

@for $i from 0 through 100 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}

/* Custom classes */
.css-dten5e {
  padding-left: 21px;
  padding-right: 21px;
  margin-bottom: 0px;
}
