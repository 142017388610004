.large {
  display: inline-block;
}
.small {
  display: none;
}
.SidebarWrapper {
  position: relative;
  z-index: 7;
  height: 100%;
  padding-bottom: 90px;
}

.social-section {
  position: fixed;
  z-index: 8;
  bottom: 0px;
  height: 90px;
  padding: 15px;
}
