@import './variables';
@import './mixins';
@import './fontStyle.scss';
@import './component/login';
@import './component/header';
@import './component/notifications';
@import './component/userbox';
@import './component/layout';
@import './component/suspenseloader';
@import './component/sidebar';
@import './component/app';
@import './component/table';
@import './overridestyles';

//common classes goes here
body {
  overflow-y: scroll !important;
  padding: 0 !important;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//multiple file upload icon size

.text-center {
  text-align: center;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}

.textNone {
  text-transform: none !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-center {
  @include flex-column-center;
}

.flex-basic-start {
  justify-content: start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-around !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}
.align-items-center {
  align-items: center !important;
}
.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}

.textweight {
  @include font-weight-bold;
}

.textalign {
  text-align: center;
}

//height
.h-100 {
  height: 100%;
}

//width per clasess

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.m-width {
  min-width: 350 !important;
}

/******* Typography *******/

.text-h1 {
  //font size 32px
  font: normal 500 2rem/36px $font-family-base;
}

.text-h2 {
  //font size 28px
  font: normal 500 1.75rem/32px $font-family-base;
}

.text-h3 {
  //font size 24px
  font: normal 500 1.5rem/28px $font-family-base;
}

.text-h4 {
  //font size 20px
  font: normal 500 1.25rem/24px $font-family-base;
}

.text-h5 {
  //font size 18px
  font: normal 500 1.125rem/20px $font-family-base;
}

.text-large {
  //font size 16px
  font: normal 500 1rem/24px $font-family-base;
}

.text-medium {
  //font size 14px
  font: normal 500 0.875rem/20px $font-family-base;
}

.text-small {
  //font size 12px
  font: normal normal 0.75rem/18px $font-family-base;
}

.text-extra-small {
  //font size 10px
  font: normal normal 0.625rem/16px $font-family-base;
}

.font-weight-bold {
  //font weight 700
  @include font-weight-bold();
}

.font-weight-semibold {
  //font weight 600
  @include font-weight-semibold();
}

.font-weight-medium {
  //font weight 500
  @include font-weight-medium();
}

.font-weight-regular {
  //font weight 400
  @include font-weight-regular();
}

.sidebarwrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.baselayout {
  flex: 1;
  height: 100%;
}

.selectformcontrol {
  width: 100%;
}

.overflow_auto {
  overflow: auto;
}

.flex_1 {
  flex: 1;
}

.overflow_x_hidden {
  overflow-x: hidden;
}

.alignitem {
  align-items: center;
}

.loginBox {
  position: relative;

  &::after {
    height: 100%;
    content: '';
    position: absolute;
    width: 1px;
    right: 0px;
    background-color: $borderBgColor;
  }
}

.commonListScroll {
  @include commonScrollBar;
  & {
    overflow: auto;
  }
}

.errorState {
  color: $errorTextColor !important;
}

.editFormPopup > .MuiDialog-container > .MuiPaper-root {
  max-width: 90% !important;
  width: 90% !important;
}

.passwordRequirement {
  background: transparent !important;
  border-radius: 0px !important;
  padding: 0px 0px 0px 5px !important;
}

.passwordRequirementList {
  padding-left: 0px !important;
  li {
    padding: 0px !important;
  }
}

.btn-180 {
  width: 12rem !important;
}

.upcomingEvents {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to left,
      rgba(92, 104, 252, 0.7708),
      rgba(0, 0, 0, 0)
    )
    1;
  width: 100%;
}

.cardBorder {
  border-bottom: 1px solid #eaeeef;
}

.eventIconContainer {
  border-radius: 50%;
  min-width: 36px;
  min-height: 36px;
  background-color: #535ffc33;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eventLink {
  @extend .text-ellipsis;
  max-width: 13rem !important;
}

.rightSectionTitle {
  min-width: fit-content;
}

.customMobileStepper {
  background-color: transparent !important;
}

.customField {
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 6px;
    }
  }
}

.customField {
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 11px;
    }
  }
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-size: 13px;
  @extend .font-weight-bold;
  line-height: inherit;
  color: #818b9a;
  letter-spacing: 0.05em !important;
  text-transform: uppercase;
}

.css-e5xz2x-MuiDivider-root {
  height: 0px !important;
}

.css-alhbss-MuiDataGrid-root {
  border-width: 0 !important;
  border-style: none !important;
}

.customSelect {
  .MuiFormControl-root {
    .MuiInputBase-root {
      .MuiSelect-select {
        padding: 8px 14px;
        margin-right: 20px;
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: $scrollBarWidth;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scrollBarColor;
  border-radius: $scrollBarRadius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scrollBarColorHover;
}

.scrollableTable {
  .tablestyle {
    .MuiDataGrid-root {
      .MuiDataGrid-main {
        div > .MuiDataGrid-virtualScroller {
          overflow-y: scroll !important;
          max-height: 157px;
          @include commonScrollBar;
        }
      }
    }
  }
}

.empDetailsBySkillTable {
  .tablestyle {
    .MuiDataGrid-root {
      .MuiDataGrid-main {
        div > .MuiDataGrid-virtualScroller {
          overflow-y: scroll !important;
          max-height: 310px !important;
          @include commonScrollBar;
        }
      }
    }
  }
}

.skilledEmpDetailsPopup {
  .MuiDialog-container {
    .MuiPaper-root {
      max-width: 45% !important;
      width: 45% !important;
      max-height: 75% !important;
    }
  }
}

.chat-bot-container {
  width: 22.75rem;
  height: 35rem;
  right: 40px;
  position: absolute;
  bottom: 50px;
  padding-right: 10px;
  background: linear-gradient(318.77deg, #0145ac -6.92%, #a771ff 87.89%),
    linear-gradient(0deg, #ffffff, #ffffff);
  @include border-radius(20px);
}

.chat-bot-header {
  margin: 20px 20px 15px 30px;
}

.chat-bot-icon-container {
  background: linear-gradient(318.77deg, #0145ac -6.92%, #a771ff 87.89%),
    linear-gradient(0deg, #ffffff, #ffffff);
  width: 55px;
  height: 55px;
  border: 3px solid $white;
  @include border-radius(50%);
}

.chat-bot-heading {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: $white;
}

.chat-bot-close-btn-container {
  padding: 10px;
  width: 30px;
  height: 30px;
  background: rgba(20, 26, 31, 0.14);
  color: $white;
  @include border-radius(50%);
  @extend .cursorPointer;
}

.chat-bot-body-container {
  width: 100%;
  margin-left: 5px;
  max-height: 465px;
  height: 465px;
  border-radius: 20px;
  background-color: $white;
  position: relative;
  overflow-y: auto;

  .MuiGrid-root {
    height: 100% !important;
    width: 100% !important;
    margin-top: 0 !important;
    margin-left: 0 !important;

    .MuiGrid-item {
      padding-left: 0 !important;
    }
  }
}

.full-screen-bot-container {
  max-height: 33rem;
  height: 33rem;
  position: relative;
}

.bot-container {
  height: 100%;
}

//customTreeView
.customTreeView {
  overflow: auto;
  @include commonScrollBar;
  & {
    height: calc(100vh - 196px);
  }
  .treeItemView {
    .MuiTreeItem-content {
      padding: 10px;
    }
  }
}

//File Manager Component
.fleManager {
  .breadcrumbItem {
    button {
      background: transparent;
      border: none;
      font-size: 14px;
      font-weight: 500;
      padding: 0 3px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .refreshIcon {
    .MuiSvgIcon-root {
      font-size: 18px;
    }
  }
  .fileActionBar {
    background-color: #efefef;
    .iconActiveColor {
      .MuiSvgIcon-root {
        color: #000 !important;
      }
    }
    .MuiBox-root {
      width: 25px;
      height: 25px;
      @include border-radius(50%);
      margin-right: 20px;
      @extend .flex-basic-center;
      @extend .cursorPointer;
      &:hover {
        background-color: #d4d4d4;
      }
      .MuiSvgIcon-root {
        font-size: 16px;
        color: #808080;
      }
    }
  }
  .fileViewSection {
    height: calc(100vh - 199px);
    .sideTreeMenu {
      width: 280px;
      border-right: 1px solid #d7d7d7;
    }
  }
}

//cardBoxFileManager
.cardBoxFileManager {
  .MuiCardContent-root {
    padding: 0px !important;
  }
}

//fileFolderListView
.fileFolderListView {
  svg[data-testid='FolderIcon'] {
    color: #efb63c;
  }
  .fileFolder {
    &:hover {
      background-color: #edefef;
    }
    &.active {
      background-color: #dae4f3;
      border: 1px solid #c5d4eb;
    }
    .MuiTypography-subtitle1 {
      color: #000;
    }
  }
}

Button .css-1nyngvs.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.demo-container {
  position: relative;
  width: 100%;
  max-width: calc(100% - 15px);
  height: 80vh;
  margin: 3em auto;
  // border: 0.1px solid #000;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.demo-container:hover {
  // transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.image-wrapper {
  height: 100%;
}

.demo-image {
  width: 100%;
  // height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.demo-container:hover .demo-image {
  transform: scale(1.05);
}

.text-overlay-container {
  position: absolute;
  top: 0;
  // left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 40px;
  color: #fff;
  transition: opacity 0.3s ease-in-out;
  // background: linear-gradient(to top, rgb(0, 0, 0), transparent);
  background: linear-gradient(to top, rgb(0 0 0), rgb(0 0 0 / 31%));
}

.text-overlay {
  position: absolute;
  top: 0;
  right: 0;
  padding: 40px;
  color: #fff;
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  transition: opacity 0.3s ease-in-out;
}

.title {
  font-weight: 700;
  margin-bottom: 1rem;
  color: #dfdfdf;
}

.description {
  margin-bottom: 4rem;
  color: #fff;
  font-size: 14px !important;
}

.launch-button {
  width: fit-content;
}
.mt-0 {
  margin-top: 0;
}

.MuiPageTitle-wrapper.PageTitle.MuiBox-root.css-0 {
  margin-inline: -15px;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
/* Style for the legend container */
.apexcharts-legend {
  font-size: 13px;
  font-weight: 600;
  color: #333;
}

/* Style for legend items */
.apexcharts-legend-series {
  padding: 0;
}

/* Hover effect for legend items */
.apexcharts-legend-series:hover {
  color: #000 !important; /* Change this color to whatever you need */
  cursor: pointer; /* Change the cursor to pointer on hover */
}

.templateWrapper {
  height: calc(100% - 300px);
  overflow: scroll;
}

.tablestyle
  .table
  .MuiDataGrid-withBorderColor
  .MuiDataGrid-columnHeaderTitleContainer,
.tablestyle .table .MuiDataGrid-row .MuiDataGrid-cell {
  justify-content: center;
  border-color: #e9e9f8;
}
.tablestyle .table .MuiDataGrid-withBorderColor {
  border-color: #e9e9f8 !important;
}

.tablestyle .MuiPaper-root,
.MuiCard-root {
  border: 1px solid #e9e9f8 !important;
}

.MuiInputBase-root {
  @include border-radius(5px);

  .MuiOutlinedInput-notchedOutline {
    border-color: $inputFieldBorderColor !important;
  }
}

// .MuiOutlinedInput-notchedOutline {
//   border-color: $inputFieldBorderColor !important;
// }
